// extracted by mini-css-extract-plugin
export var Article = "style-module--Article--0b3c4";
export var BackArrow = "style-module--BackArrow--83fb8";
export var Banner = "style-module--Banner--052d2";
export var BannerCaption = "style-module--BannerCaption--ce19c";
export var BannerImage = "style-module--BannerImage--45afc";
export var Body = "style-module--Body--66d5a";
export var Breadcrumb = "style-module--Breadcrumb--9dd35";
export var Category = "style-module--Category--8b0a1";
export var Content = "style-module--Content--0f1a9";
export var Details = "style-module--Details--01740";
export var Footer = "style-module--Footer--3982e";
export var Header = "style-module--Header--f3d66";
export var Keyword = "style-module--Keyword--ebec8";
export var ReadingTime = "style-module--ReadingTime--1f23c";